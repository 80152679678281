import React from 'react';
import { Navigate } from 'react-router';

const FourOFour = () => {
	return (
		<div>
			<Navigate to='/404' />
		</div>
	);
};

export default FourOFour;
